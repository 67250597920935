<template>
  <div>
    <v-card>
      <v-card-title>All Reviews</v-card-title>
      <v-card-text class="d-flex">
        <v-btn depressed class="mr-2" @click="exportAll">
          Export all reviews
        </v-btn>
        <v-btn depressed @click="goToImport">
          Import reviews
        </v-btn>
      </v-card-text>
      <v-toolbar dense flat outlined>
        <v-menu offset-y :close-on-click="false" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" depressed color="primary" outlined class="ml-2">
              Status
              <v-icon right dark>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in status" :key="item.key" link>
              <v-list-item-title>
                <v-checkbox v-model="item.checked" :label="item.value" hide-details></v-checkbox>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" depressed color="warning" outlined class="ml-2">
              Actions
              <v-icon right dark>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="action in actions" :key="action.title" link>
              <v-list-item-title @click="action.hander()">
                {{ action.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-text-field
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
          placeholder="Search..."
        ></v-text-field>
      </v-toolbar>
      <ReviewTable :search="search" :productId="''" :type="'store'" v-on:inputChange="updateSelected" />
    </v-card>
  </div>
</template>

<script>
import { exportAllReviews } from '../../../../apis/review';

import ReviewTable from './components/ReviewTable.vue';
export default {
  data() {
    let query = this.$route.query;
    return {
      tab: query?.status === '0' ? 'tab-unpublished' : 'tab-published',
      status: [
        { key: 0, value: 'Unpublished', checked: false },
        { key: 1, value: 'Published', checked: true },
      ],
      drawer: false,
      search: '',
      searchingTimeout: null,
      selectedStatus: [],
      selected: [],
      actions: [
        {
          title: 'Delete all store reviews',
          hander: this.deleteAllReviews,
        },
        {
          title: 'Delete all product reviews',
          hander: this.deleteAllProductReviews,
        },
        {
          title: 'Delete selected',
          hander: this.deleteReviews,
        },
      ],
    };
  },
  components: {
    ReviewTable,
  },
  methods: {
    async exportAll() {
      try {
        let res = await exportAllReviews();
        let url = res && res.data ? res.data.Location : null;
        if (url && url != '') location.href = url;
      } catch (err) {}
    },
    toggleSideBar() {
      this.drawer = !this.drawer;
    },
    goToImport() {
      this.$router.push({ name: 'website.apps.product-review.imports' });
    },
    searchChange() {
      if (this.searchingTimeout) this.searchingTimeout = null;
      let query = this.$route.query;
      this.searchingTimeout = setTimeout(() => {
        let queries = {
          status: this.getStatus,
          search: this.search,
          page: 1,
          limit: query.limit,
        };
        this.$store.dispatch('fetchReviews', queries);
        this.updateQuery(queries);
      }, 2000);
    },
    updateQuery(queries) {
      this.$router.push({
        query: queries,
      });
    },
    deleteReviews() {
      if (!this.selected.length) return;
      this.$store
        .dispatch('deleteReviews', {
          ids: this.selected.join(','),
          type: 'store',
        })
        .then(() => {
          console.log('deleted');
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteAllProductReviews() {
      if (confirm('Are you sure to delete all review in product ?')) {
        this.$store
          .dispatch('deleteReviews', { type: 'product' })
          .then(() => {
            console.log('deleted');
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    deleteAllReviews() {
      if (confirm('Are you sure to delete all review in store ?')) {
        this.$store
          .dispatch('deleteReviews', { type: 'store' })
          .then(() => {
            console.log('deleted');
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    updateSelected(selected) {
      this.selected = selected;
    },
  },
  computed: {
    getStatus() {
      let computeStatus = this.tab == 'tab-published' ? 1 : this.tab == 'tab-unpublished' ? 0 : null;
      return computeStatus;
    },
  },
  watch: {
    tab(val) {
      console.log(val);
      const query = this.$route.query;
      let queries = {
        status: this.getStatus,
        search: this.search,
        page: 1,
        limit: query.limit,
      };
      this.$store.dispatch('fetchReviews', queries);
      this.updateQuery(queries);
    },
    search(val) {
      this.searchChange();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-normal-custom--tab {
  text-transform: none;
  color: #000000;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.v-text-field {
  margin-top: 0px !important;
}
</style>
